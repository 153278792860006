import { DiToken, registerDiToken } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { EnvBf, EnvCommon } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ENV_BF: DiToken<EnvBf> = new DiToken<EnvBf>('EnvBf');

export function registerEnvBf(envBf: EnvBf): void {
  registerDiToken(ENV_BF, () => envBf);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ENV_COMMON: DiToken<EnvCommon> = new DiToken<EnvCommon>('EnvCommon');

export function registerEnvCommon(envCommon: EnvCommon): void {
  registerDiToken(ENV_COMMON, () => envCommon);
}

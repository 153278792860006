import { Observable, delay, of, take } from 'rxjs';

type SwRegistrationOptions = {
  enabled?: boolean;
  scope?: string;
  registrationStrategy?: string | (() => Observable<unknown>);
};

export function litswAppInitializer(script: string, options: SwRegistrationOptions): void {
  if (!('serviceWorker' in navigator && options.enabled !== false)) {
    return;
  }
  // Wait for service worker controller changes, and fire an INITIALIZE action when a new SW
  // becomes active. This allows the SW to initialize itself even if there is no application
  // traffic.
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (navigator.serviceWorker.controller !== null) {
      navigator.serviceWorker.controller.postMessage({ action: 'INITIALIZE' });
    }
  });

  let readyToRegister$: Observable<unknown>;

  if (typeof options.registrationStrategy === 'function') {
    readyToRegister$ = options.registrationStrategy();
  } else {
    // eslint-disable-next-line @typescript-eslint/typedef
    const [strategy, ...args] = (options.registrationStrategy || 'registerWhenStable:3000').split(':');

    switch (strategy) {
      case 'registerImmediately':
        readyToRegister$ = of(null);
        break;
      case 'registerWithDelay':
        readyToRegister$ = delayWithTimeout(+args[0] || 0);
        break;
      case 'registerWhenStable':
        readyToRegister$ = delayWithTimeout(+args[0] || 0);
        break;
      default:
        // Unknown strategy.
        throw new Error(`Unknown ServiceWorker registration strategy: ${options.registrationStrategy}`);
    }
  }

  readyToRegister$.pipe(take(1)).subscribe(() => {
    navigator.serviceWorker
      .register(script, { scope: options.scope })
      .catch((err: unknown) => console.error('Service worker registration failed with:', err));
  });
}

function delayWithTimeout(timeout: number): Observable<unknown> {
  return of(null).pipe(delay(timeout));
}

import { EnvTma } from '@bcflit-env/types';
import { createContext } from '@lit/context';

export class SharedSettings {
  private _env!: EnvTma;

  public set env(env: EnvTma) {
    if (this._env) {
      throw new Error('you can setup environment just once');
    }
    this._env = env;
  }

  public get env(): EnvTma {
    if (!this._env) {
    }
    return this._env;
  }
}

export const sharedSettings: SharedSettings = new SharedSettings();

// eslint-disable-next-line @typescript-eslint/typedef
export const sharedSettingsContext = createContext<SharedSettings>('SharedSettings');

import { LoginInfo } from '@tmf-shared-models/login-info';
import { NotificationInfo } from '@tmf-shared-models/notification-info';
import { UserBalance } from '@tmf-shared-models/user-balance';
import { UserInfo } from '@tmf-shared-models/user-info';
import { UserLocation } from '@tmf-shared-models/user-location';
import { UserSyndicateSession } from '@tmf-shared-models/user-syndicate-session';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, ReplaySubject } from 'rxjs';
import { mapToTotalSyndicatesAmountShares } from './pure-utils';

export class UserDataInfo {
  public loginInfo$: BehaviorSubject<LoginInfo | undefined> = new BehaviorSubject<LoginInfo | undefined>(undefined);
  public userInfo$: BehaviorSubject<UserInfo | undefined> = new BehaviorSubject<UserInfo | undefined>(undefined);
  public userBalances$: BehaviorSubject<UserBalance[] | undefined> = new BehaviorSubject<UserBalance[] | undefined>(
    undefined
  );
  public userSyndicateSessions$: BehaviorSubject<UserSyndicateSession[] | undefined> = new BehaviorSubject<
    UserSyndicateSession[] | undefined
  >(undefined);
  public userNotificationsUnread$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public userNotifications$: BehaviorSubject<NotificationInfo[]> = new BehaviorSubject<NotificationInfo[]>([]);

  public userLocation$: ReplaySubject<UserLocation> = new ReplaySubject<UserLocation>(1);

  public get userRealBalance$(): Observable<UserBalance | undefined> {
    return this.userBalances$.pipe(
      map((balances: UserBalance[] | undefined) => balances?.find((balance: UserBalance) => balance.kind === 'REAL'))
    );
  }

  public get userBonusBalance$(): Observable<UserBalance | undefined> {
    return this.userBalances$.pipe(
      map((balances: UserBalance[] | undefined) => balances?.find((balance: UserBalance) => balance.kind === 'BONUS'))
    );
  }

  public get totalSyndicatesAmountShares$(): Observable<number> {
    return this.userSyndicateSessions$.pipe(map(mapToTotalSyndicatesAmountShares));
  }

  public userCountry$: Observable<string> = combineLatest([this.userLocation$, this.userInfo$]).pipe(
    map(
      ([userLocation, userInfo]: [UserLocation, UserInfo | undefined]) =>
        userInfo?.countryCode ?? userLocation.countryCode
    ),
    distinctUntilChanged()
  );
}

export const userDataInfo: UserDataInfo = new UserDataInfo();

export type CmsPaymentSettingsSelectOption = {
  readonly display: string;
  readonly value: string;
};

export type CmsPaymentValidationType = 'iban' | 'swift-bic' | 'only-letters' | 'required';

export type CmsPaymentSettingsFieldConfig = {
  readonly name: string;
  readonly label: string;
  readonly type: 'text' | 'expiration-date' | 'select';
  readonly validationType: CmsPaymentValidationType[] | undefined;
  readonly iconName: string | undefined;
  readonly selectOptions: CmsPaymentSettingsSelectOption[] | undefined;
};

export type CmsPaymentSettings = {
  readonly id: string;
  readonly name: string;
  readonly imageUrl: string;
  readonly formFields: CmsPaymentSettingsFieldConfig[][] | undefined;
};

function createCmsPaymentSettingsFieldConfig(
  jsonArray: Record<string, any>[]
): CmsPaymentSettingsFieldConfig[][] | undefined {
  if (jsonArray.length === 0) {
    return undefined;
  }
  const container: CmsPaymentSettingsFieldConfig[][] = [];
  for (const innerJson of jsonArray) {
    const groupContainer: CmsPaymentSettingsFieldConfig[] = [];
    for (const inner1Json of innerJson['fields']['data']) {
      let selectData: CmsPaymentSettingsSelectOption[] | undefined;
      if (inner1Json['attributes']['cryptocurrency'] && inner1Json['attributes']['cryptocurrency'].length > 0) {
        selectData = [];
        for (const selectDataJson of inner1Json['attributes']['cryptocurrency'][0]['currencies']['data']) {
          const selectOption: CmsPaymentSettingsSelectOption = {
            value: selectDataJson['attributes']['value'],
            display: selectDataJson['attributes']['display']
          };
          selectData.push(selectOption);
        }
      }

      const validationTypes: CmsPaymentValidationType[] = [];
      for (const ruleObj of inner1Json['attributes']['fieldRules']) {
        validationTypes.push(ruleObj['Rules']);
      }

      const fieldConfig: CmsPaymentSettingsFieldConfig = {
        name: inner1Json['attributes']['name'],
        label: inner1Json['attributes']['label'],
        type: inner1Json['attributes']['type'],
        iconName: inner1Json['attributes']['iconName'],
        selectOptions: selectData,
        validationType: validationTypes.length > 0 ? validationTypes : undefined
      };
      groupContainer.push(fieldConfig);
    }
    container.push(groupContainer);
  }
  return container;
}

export function createCmsPaymentSettingsFromHttpData(httpData: Record<string, any>): CmsPaymentSettings[] {
  const container: CmsPaymentSettings[] = [];
  for (const jsonItem of httpData['data']) {
    container.push({
      id: jsonItem['attributes']['methodID'],
      name: jsonItem['attributes']['entryName'],
      imageUrl: jsonItem['attributes']['image']['data']['attributes']['url'],
      formFields: createCmsPaymentSettingsFieldConfig(jsonItem['attributes']['formFields'])
    });
  }
  return container;
}

export enum ModalKind {
  login = 'login',
  completeUserInfo = 'complete-user-info',
  register = 'register',
  paymentStatus = 'payment-status',
  forgotPassword = 'forgot-password',
  resetPassword = 'reset-password',
  activateAccount = 'activate-account',
  searchGames = 'search-games',
  deposit = 'deposit',
  withdraw = 'withdraw',
  playTogetherCreateSession = 'play-together-create-session',
  playTogetherOnboarding = 'play-together-onboarding',
  countryBlocked = 'country-blocked'
}

export enum ModalEvent {
  doCloseModal = 'doCloseModal'
}

export enum BottomSheetEvent {
  doCloseBottomSheet = 'doCloseBottomSheet'
}

export enum BottomSheetKind {
  countryBlocked = 'country-blocked'
}

export enum ScrollRestoreKey {
  casinoScrollRestore = 'casinoScrollRestore'
}

export enum StaticsKind {
  termsAndConditions = 'terms-and-conditions',
  bonusTerms = 'bonus-terms',
  privacyPolicy = 'privacy-policy',
  cookiePolicy = 'cookie-policy',
  responsibleGambling = 'responsible-gambling',
  refundPolicy = 'refund-policy'
}

import { Observable, ReplaySubject } from 'rxjs';

export class ActivatedLangConst {
  private _lang!: string;
  private _lang$: ReplaySubject<string> = new ReplaySubject<string>(1);

  public setLang(lang: string): void {
    this._lang = lang;
    this._lang$.next(lang);
  }

  public get lang(): string {
    return this._lang;
  }

  public getLang(): Observable<string> {
    return this._lang$;
  }
}

export const activatedLangConst: ActivatedLangConst = new ActivatedLangConst();

type Ctor = abstract new (...args: any) => any;

function registerStore(): void {
  const globalAny: any = globalThis;
  if (!globalAny['_diStore']) {
    globalAny['_diStore'] = new Map();
    globalAny['_diStoreReplace'] = new Map();
  }
}

function getStore<T extends Ctor>(): Map<T, InstanceType<T> | undefined> {
  const globalAny: any = globalThis;
  return globalAny['_diStore'];
}

function getReplaceStore<T extends Ctor, R extends Ctor>(): Map<T, () => InstanceType<R>> {
  const globalAny: any = globalThis;
  return globalAny['_diStoreReplace'];
}

export function provideSingleton<T extends Ctor>(klazz: T, singletonFactory: () => InstanceType<T>): InstanceType<T> {
  registerStore();
  const diStore: Map<T, InstanceType<T> | undefined> = getStore<T>();
  const replaceStore: Map<T, () => InstanceType<T>> = getReplaceStore();

  const fixedSingletonFactory: () => InstanceType<T> = replaceStore.get(klazz) ?? singletonFactory;

  if (!diStore.has(klazz)) {
    diStore.set(klazz, fixedSingletonFactory());
  }
  return diStore.get(klazz)!;
}

export function replaceDiImpl<T extends Ctor, R extends Ctor>(klazz: T, singletonFactory: () => InstanceType<R>): void {
  registerStore();
  const diStore: Map<T, InstanceType<T> | undefined> = getStore<T>();
  const replaceStore: Map<T, () => InstanceType<R>> = getReplaceStore();
  if (diStore.has(klazz)) {
    console.error('already initialized singleton!! wrong!!!', klazz);
    return;
  }
  if (replaceStore.has(klazz)) {
    console.warn('You already set replaceDi for class', klazz);
  }
  replaceStore.set(klazz, singletonFactory());
}

import { createContext } from '@lit/context';
import { get, set } from 'idb-keyval';
import { Subscription, fromEvent } from 'rxjs';
import { ScrollRestoreKey } from './enums';

export class ScrollRestorer {
  public init(scrollElement: HTMLElement, scrollRestoreKey: ScrollRestoreKey | undefined): Subscription {
    if (!scrollRestoreKey) {
      return new Subscription();
    }
    this._restoreScroll(scrollElement, scrollRestoreKey);
    return fromEvent(scrollElement, 'scroll').subscribe(() => set(scrollRestoreKey, scrollElement.scrollTop));
  }

  private async _restoreScroll(scrollElement: HTMLElement, scrollRestoreKey: ScrollRestoreKey): Promise<void> {
    const scrollValue: number | undefined = await get(scrollRestoreKey);
    if (scrollValue) {
      scrollElement.scrollTop = scrollValue;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/typedef
export const scrollRestorerContext = createContext<ScrollRestorer>('scrollRestorerContext');

import { consume } from '@lit/context';
import { TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import { ActivatedLang, activatedLangContext } from '@tmf-shared-platform/activated-lang/activated-lang';
import {
  ActiveDomainRedirectorController,
  provideActiveDomainRedirectorController
} from '@tmf-ui-misc-controller/active-domain-redirector/active-domain-redirector.controller';
import { SharedSettings, sharedSettingsContext } from '@tmp-shared-settings/settings/shared-settings';
import { TemplateResult, html } from 'lit';
import { property } from 'lit/decorators.js';

export class ActiveDomainRedirectorSmartElement extends TmfLitElement {
  public static override elementSelector: string = 'tmf-jzm-active-domain-redirector-smart';

  private _controller: ActiveDomainRedirectorController = provideActiveDomainRedirectorController(
    this,
    () => this.sharedSettings,
    () => this.activatedLang
  );

  @consume({ context: sharedSettingsContext })
  @property({ attribute: false })
  public sharedSettings!: SharedSettings;

  @consume({ context: activatedLangContext })
  @property({ attribute: false })
  public activatedLang!: ActivatedLang;

  public override connectedCallback(): void {
    super.connectedCallback();
  }

  public override disconnectedCallback(): void {
    super.disconnectedCallback();
  }

  public override render(): TemplateResult {
    return html``;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmf-jzm-active-domain-redirector-smart': ActiveDomainRedirectorSmartElement;
  }
}

import { pathNormalize } from './path-normalize';

// copies from path-browserify
export function pathJoin(...args: string[]): string {
  if (args.length === 0) {
    return '.';
  }
  let joined: string | undefined;
  for (let i: number = 0; i < args.length; ++i) {
    const arg: string = args[i];
    if (arg.length > 0) {
      if (joined === undefined) {
        joined = arg;
      } else {
        joined += '/' + arg;
      }
    }
  }
  if (joined === undefined) {
    return '.';
  }
  return pathNormalize(joined);
}

import { UserDataInfo, userDataInfo } from '@tmf-logic-auth/services/user-data-info';
import { Bloc } from '@tmf-shared-classes/bloc';
import { DomainSettingsCountry } from '@tmf-shared-models/domain-settings';
import {
  DomainSettingsInfo,
  provideDomainSettingsInfo
} from '@tmf-shared-platform/domain-settings/domain-settings-info';
import { Observable, ReplaySubject, combineLatest, map } from 'rxjs';
import { mapToDomainSettingsCountry } from './pure-utils/map-to-domain-settings-country';

export class ActiveDomainRedirectorBloc extends Bloc {
  public currentLang$: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor(
    private _userDataInfo: UserDataInfo,
    private _domainSettingsInfo: DomainSettingsInfo
  ) {
    super();
  }

  public getDomainSettingsForCountryIp(): Observable<DomainSettingsCountry | undefined> {
    return combineLatest([
      this._userDataInfo.userLocation$,
      this.currentLang$,
      this._domainSettingsInfo.getDomainSettings()
    ]).pipe(map(mapToDomainSettingsCountry));
  }
}

export function provideActiveDomainRedirectorBloc(): ActiveDomainRedirectorBloc {
  return new ActiveDomainRedirectorBloc(userDataInfo, provideDomainSettingsInfo());
}

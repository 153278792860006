function setCookie(key: string, value: string, document: Document): void {
  let cookieString: string = encodeURIComponent(key) + '=' + encodeURIComponent(value) + ';';
  const expiryDays: number = 3;
  const dateExpires: Date = new Date(new Date().getTime() + expiryDays * 1000 * 60 * 60 * 24);
  cookieString += 'expires=' + dateExpires.toUTCString() + ';';
  cookieString += 'path=' + '/' + ';';
  document.cookie = cookieString;
}

export function setReleaseVersion(releaseVersion: string, document: Document): void {
  const tmfApp: Element | undefined = Array.from(document.getElementsByTagName('tmf-app'))?.[0];
  tmfApp?.setAttribute('tmf-version', releaseVersion);
  setCookie('tmfVersion', releaseVersion, document);
  setCookie('tmfAppRuntimeId', new Date().getTime().toString(), document);
}

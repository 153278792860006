import { UserAuthState } from '@tmf-logic-auth/misc/enums';
import { AuthToken } from '@tmf-shared-models/auth-token';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo } from 'rxjs/operators';

export class AuthState {
  private _authState$: BehaviorSubject<UserAuthState> = new BehaviorSubject<UserAuthState>(UserAuthState.notChecked);
  private _authToken$: BehaviorSubject<AuthToken | undefined> = new BehaviorSubject<AuthToken | undefined>(undefined);
  private _userId$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  public getAuthState(): Observable<UserAuthState> {
    return this._authState$.pipe(distinctUntilChanged());
  }

  public getAuthToken(): Observable<AuthToken | undefined> {
    return this._authToken$;
  }

  public getUserId(): Observable<string | undefined> {
    return this._userId$;
  }

  public isLoggedIn(): Observable<boolean> {
    return this.getAuthState().pipe(
      map((state: UserAuthState) => state === UserAuthState.isLoggedIn),
      distinctUntilChanged()
    );
  }

  public isChecked(): Observable<UserAuthState> {
    return this.getAuthState().pipe(
      filter((state: UserAuthState) => state !== UserAuthState.notChecked),
      distinctUntilChanged()
    );
  }

  public isCheckedAndLoggedIn(): Observable<boolean> {
    return this.getAuthState().pipe(
      filter((state: UserAuthState) => state !== UserAuthState.notChecked),
      map((state: UserAuthState) => state === UserAuthState.isLoggedIn),
      distinctUntilChanged()
    );
  }

  public isLoggedInSignal(): Observable<void> {
    return this.getAuthState().pipe(
      filter((state: UserAuthState) => state === UserAuthState.isLoggedIn),
      mapTo(undefined)
    );
  }

  public isNotLoggedInSignal(): Observable<void> {
    return this.getAuthState().pipe(
      filter((state: UserAuthState) => state === UserAuthState.isNotLoggedIn),
      mapTo(undefined)
    );
  }

  public changeAuthState(state: UserAuthState): void {
    this._authState$.next(state);
  }

  public setAuthToken(authToken: AuthToken): void {
    this._authToken$.next(authToken);
  }

  public setUserId(userId: string | undefined): void {
    this._userId$.next(userId);
  }
}

export const authState: AuthState = new AuthState();

import { Game } from '@tmf-shared-models/brand-game';
import { CmsSeoSettingsConfig } from '@tmf-shared-models/cms-seo-settings';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

type CmsSeoSettingsConfigForGame = {
  config: CmsSeoSettingsConfig;
  slugName: string;
};

export class CmsSeoSettingsForGame {
  private _seoSettingsConfig$: BehaviorSubject<CmsSeoSettingsConfigForGame | undefined> = new BehaviorSubject<
    CmsSeoSettingsConfigForGame | undefined
  >(undefined);

  private _currentOpenGame$: BehaviorSubject<Game | undefined> = new BehaviorSubject<Game | undefined>(undefined);

  public updateSeoSettings() {
    return ([seoSettings, slugName]: [CmsSeoSettingsConfig | undefined, string]): void => {
      if (!seoSettings) {
        this._seoSettingsConfig$.next(undefined);
        return;
      }
      this._seoSettingsConfig$.next({
        config: seoSettings,
        slugName: slugName
      });
    };
  }

  public getSeoSettingsConfig(): Observable<CmsSeoSettingsConfig | undefined> {
    return combineLatest(this._seoSettingsConfig$, this._currentOpenGame$).pipe(
      map(([seoSettingsForGame, game]: [CmsSeoSettingsConfigForGame | undefined, Game | undefined]) => {
        if (seoSettingsForGame && game && seoSettingsForGame.slugName === game.slug) {
          return seoSettingsForGame.config;
        }
        return undefined;
      })
    );
  }

  public setCurrentOpenGame() {
    return (game: Game | undefined): void => {
      this._currentOpenGame$.next(game);
    };
  }

  public clearCurrentOpenGame(): void {
    this._currentOpenGame$.next(undefined);
  }
}

export const cmsSeoSettingsForGame: CmsSeoSettingsForGame = new CmsSeoSettingsForGame();

import { consume } from '@lit/context';
import { UnsubscribableTmfLitElement } from '@tmf-shared-classes/unsubscribable-tmf-lit-element';
import { ActivatedLang, activatedLangContext } from '@tmf-shared-platform/activated-lang/activated-lang';
import { ActivatedUrl, activatedUrlContext } from '@tmf-shared-platform/activated-url/activated-url';
import {
  CmsSeoMetaUpdaterController,
  provideCmsSeoMetaUpdaterController
} from '@tmf-ui-cms-controller/cms-seo-meta-updater/cms-seo-meta-updater.controller';
import { SharedSettings, sharedSettingsContext } from '@tmp-shared-settings/settings/shared-settings';
import { TemplateResult, html } from 'lit';
import { property } from 'lit/decorators.js';

export class CmsSeoMetaUpdaterSmartElement extends UnsubscribableTmfLitElement {
  public static override elementSelector: string = 'tmf-jzm-cms-seo-meta-updater-smart';

  private _controller: CmsSeoMetaUpdaterController = provideCmsSeoMetaUpdaterController(
    this,
    () => this.activatedUrl,
    () => this.sharedSettings,
    () => this.activatedLang
  );

  @consume({ context: activatedUrlContext })
  @property({ attribute: false })
  public activatedUrl!: ActivatedUrl;

  @consume({ context: sharedSettingsContext })
  @property({ attribute: false })
  public sharedSettings!: SharedSettings;

  @consume({ context: activatedLangContext })
  @property({ attribute: false })
  public activatedLang!: ActivatedLang;

  public override connectedCallback(): void {
    super.connectedCallback();
  }

  public override render(): TemplateResult {
    return html``;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmf-jzm-cms-seo-meta-updater-smart': CmsSeoMetaUpdaterSmartElement;
  }
}

import { consume } from '@lit/context';
import { UnsubscribableTmfLitElement } from '@tmf-shared-classes/unsubscribable-tmf-lit-element';
import { ActivatedUrl, activatedUrlContext } from '@tmf-shared-platform/activated-url/activated-url';
import {
  CmsSeoJsonLdUpdaterController,
  provideCmsSeoJsonLdUpdaterController
} from '@tmf-ui-cms-controller/cms-seo-json-ld-updater/cms-seo-json-ld-updater.controller';
import { TemplateResult, html } from 'lit';
import { property } from 'lit/decorators.js';

export class CmsSeoJsonLdUpdaterSmartElement extends UnsubscribableTmfLitElement {
  public static override elementSelector: string = 'tmf-jzm-cms-seo-json-ld-updater-smart';

  private _controller: CmsSeoJsonLdUpdaterController = provideCmsSeoJsonLdUpdaterController(
    this,
    () => this.activatedUrl
  );

  @consume({ context: activatedUrlContext })
  @property({ attribute: false })
  public activatedUrl!: ActivatedUrl;

  public override connectedCallback(): void {
    super.connectedCallback();
  }

  public override render(): TemplateResult {
    return html``;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmf-jzm-cms-seo-json-ld-updater-smart': CmsSeoJsonLdUpdaterSmartElement;
  }
}

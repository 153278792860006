import {
  CmsSeoJsonLdUpdaterBloc,
  provideCmsSeoJsonLdUpdaterBloc
} from '@tmf-logic-cms/bloc/cms-seo-json-ld-updater/cms-seo-json-ld-updater.bloc';
import { UnsubscribableController } from '@tmf-shared-classes/unsubscribable-controller';
import { transferRxNext } from '@tmf-shared-misc/rx-helpers';
import { ActivatedUrl } from '@tmf-shared-platform/activated-url/activated-url';
import { ReactiveControllerHost } from 'lit';

export class CmsSeoJsonLdUpdaterController extends UnsubscribableController<CmsSeoJsonLdUpdaterController> {
  private _jsonLdScript: HTMLScriptElement | undefined;

  constructor(
    protected override _host: ReactiveControllerHost,
    protected override _bloc: CmsSeoJsonLdUpdaterBloc,
    private _activatedUrlFn: () => ActivatedUrl
  ) {
    super(_host);
  }

  public hostConnected(): void {
    this._sub = this._activatedUrlFn().url$.subscribe(transferRxNext(this._bloc.currentUrl$));
    this._sub = this._bloc.getJsonLdContent().subscribe(this._updateJsonLd());
  }

  private _updateJsonLd() {
    return (jsonLdContent: Record<string, any> | undefined): void => {
      if (!jsonLdContent && this._jsonLdScript) {
        this._jsonLdScript?.remove();
        this._jsonLdScript = undefined;
        return;
      }

      if (!jsonLdContent) {
        return;
      }

      if (!this._jsonLdScript) {
        this._jsonLdScript = document.createElement('script');
        document.head.appendChild(this._jsonLdScript);
      }
      this._jsonLdScript!.type = 'application/ld+json';
      this._jsonLdScript!.textContent = JSON.stringify(jsonLdContent);
    };
  }
}

export function provideCmsSeoJsonLdUpdaterController(
  host: ReactiveControllerHost,
  activatedUrlFn: () => ActivatedUrl
): CmsSeoJsonLdUpdaterController {
  return new CmsSeoJsonLdUpdaterController(host, provideCmsSeoJsonLdUpdaterBloc(), activatedUrlFn);
}

export type CmsCountry = {
  countryCode: string;
  name: string;
};

export function createCmsCountryFromHttpData(httpData: Record<string, any>): CmsCountry[] {
  const container: CmsCountry[] = [];

  for (const item of httpData['data']['attributes']['Country']) {
    const country: CmsCountry = {
      countryCode: item['countryCode'].toUpperCase(),
      name: item['countryName']
    };
    container.push(country);
  }

  return container;
}

export function createCmsCountryBlockedFromHttpData(httpData: Record<string, any>): CmsCountry[] {
  const container: CmsCountry[] = [];

  for (const item of httpData['data']['attributes']['Countries']) {
    const country: CmsCountry = {
      countryCode: item['countryCode'].toUpperCase(),
      name: item['countryName']
    };
    container.push(country);
  }

  return container;
}

import { EnvCommon, EnvTma } from '@bcflit-env/types';
import { envBaseCommon } from './env.base';
import { envCommon as envCommonOld } from './env.common';

export const env: EnvTma = {
  ...(envCommonOld as EnvTma),
  brandId: 'jz',
  graphqlApiUrl: 'https://api.januszcasino{versionNumber}.com/gql/',
  graphqlWsUrl: 'wss://api.januszcasino{versionNumber}.com/wss/',
  gameAssetsBaseUrl: 'https://api.januszcasino{versionNumber}.com/game-assets/',
  cmsApiUrl: 'https://cms.januszcasino{versionNumber}.com/',
  allGamesCollection: 'jz_all-games',
  webpConfig: {
    brand: 'januszcasino',
    hostUrl: 'https://januszcasino{versionNumber}.com/'
  }
};

export const envCommon: EnvCommon = {
  ...envBaseCommon,
  svgIconConfig: {
    brand: 'januszcasino',
    hostUrl: 'https://januszcasino{versionNumber}.com/'
  }
};

import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { BehaviorSubject } from 'rxjs';
import {
  BottomSheetStandaloneResolverSignalService,
  provideBottomSheetStandaloneResolverSignalService
} from './bottom-sheet-standalone-resolver-signal.service';

export class BottomSheetCookiesConsentService {
  public show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _bottomSheetsStandaloneResolverSignal: BottomSheetStandaloneResolverSignalService) {
    this._init();
  }

  private _init(): void {
    this.show$.subscribe(this._bottomSheetsStandaloneResolverSignal.resolve('cookies-consent'));
  }
}
export function provideBottomSheetCookiesConsentService(): BottomSheetCookiesConsentService {
  return provideSingleton(
    BottomSheetCookiesConsentService,
    () => new BottomSheetCookiesConsentService(provideBottomSheetStandaloneResolverSignalService())
  );
}

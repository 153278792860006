export type CmsCasinoCategory = {
  readonly title: string;
  readonly collectionId: string;
};

function createCmsCasinoCategoryFromData(dataJson: Record<string, any>[]): CmsCasinoCategory[] {
  const container: CmsCasinoCategory[] = [];
  for (const json of dataJson) {
    container.push({
      title: json['title'],
      collectionId: json['collectionID']
    });
  }
  return container;
}

export function createCmsCasinoCategoriesFromHttpData(httpData: Record<string, any>): CmsCasinoCategory[] {
  const dataJson: Record<string, any>[] = httpData['data'][0]['attributes']['collections'];
  return createCmsCasinoCategoryFromData(dataJson);
}

import { EnvTma } from '@bcflit-env/types';
import { initI18n } from '../../../init-i18n';

async function loadLocalize(lang: string): Promise<Record<string, any>> {
  if (lang === 'pl') {
    // eslint-disable-next-line @typescript-eslint/typedef
    return await import('@bcflit-i18n/janusz-casino/generated/locales/pl').then((mod) => mod.templates);
  }
  if (lang === 'hu') {
    // eslint-disable-next-line @typescript-eslint/typedef
    return await import('@bcflit-i18n/janusz-casino/generated/locales/hu').then((mod) => mod.templates);
  }
  if (lang === 'de') {
    // eslint-disable-next-line @typescript-eslint/typedef
    return await import('@bcflit-i18n/janusz-casino/generated/locales/de').then((mod) => mod.templates);
  }
  if (lang === 'cs') {
    // eslint-disable-next-line @typescript-eslint/typedef
    return await import('@bcflit-i18n/janusz-casino/generated/locales/cs').then((mod) => mod.templates);
  }
  return {};
}

export async function bootstrapAppI18n(env: EnvTma): Promise<void> {
  initI18n(env, (lang: string) => loadLocalize(lang));
}

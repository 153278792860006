import { configureLocalization } from '@lit/localize';
import { extractCookies } from '@tmf-shared-platform/cookie-storage/pure-utils/extract-cookies';

type LocalizeFns = {
  getLocale: () => string;
  setLocale(locale: string): void;
};

function getBaseHrefPathname(): string {
  const url: URL = new URL(document.getElementsByTagName('base')[0].href);
  return url.pathname;
}

function detectLang(baseHref: string): string | undefined {
  const pathname: string = window.location.pathname.endsWith('/')
    ? window.location.pathname
    : window.location.pathname + '/';
  const pathnameFixed: string[] = pathname.replace(baseHref, '').split('/');
  const langSegment: string | undefined = pathnameFixed?.[0];
  return langSegment?.length > 0 ? langSegment : undefined;
}

function langFromMemory(): string | undefined {
  return localStorage.getItem('app_lang') ?? undefined;
}

function langToMemory(lang: string): void {
  localStorage.setItem('app_lang', lang);
}

type EnvWithLangs = {
  langs: string[];
};

export async function initI18n(
  env: EnvWithLangs,
  loadLocalizeFn: (lang: string) => Promise<Record<string, any>>
): Promise<void> {
  const baseHref: string = getBaseHrefPathname();
  const lang: string | undefined = detectLang(baseHref);
  if (lang && !env.langs.includes(lang)) {
    window.location.href = baseHref;
    return;
  }
  if (!lang) {
    const cookies: Record<string, string> = extractCookies(document.cookie);
    const defaultCountry: string =
      env.langs.find((lang: string) => cookies['tmfCountry'] && lang === cookies['tmfCountry'].toLowerCase()) ??
      env.langs[0];
    window.location.href = baseHref + `${langFromMemory() ?? defaultCountry}/`;
    return;
  }
  langToMemory(lang!);
  const translations: Record<string, string> = await loadLocalizeFn(lang!);
  const functions: LocalizeFns = configureLocalization({
    sourceLocale: 'en',
    targetLocales: env.langs,
    loadLocale: async () => ({
      templates: translations
    })
  });
  functions.setLocale(lang!);
}

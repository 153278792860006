import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { from, map, mergeMap, Observable, OperatorFunction, switchMap, throwError } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { HttpOptions } from './types';

export type HttpError = {
  readonly _type: '_HttpError';
  readonly url: string;
  readonly status: number;
  readonly statusText: string;
  readonly error: Record<string, any> | undefined;
  readonly errorAsString: string | undefined;
  readonly responseHeaders: Record<string, string>;
};

type Mappers<T> = [(json: any) => T, <R>(errorJson: any) => R] | [(json: any) => T];

async function extractJson(responseText: string): Promise<Record<string, any> | undefined> {
  try {
    return JSON.parse(responseText);
  } catch (err) {
    return undefined;
  }
}

export async function createHttpError(response: Response): Promise<HttpError> {
  const responseHeaders: Record<string, string> = {};

  response.headers.forEach((value: string, key: string) => {
    responseHeaders[key] = value;
  });

  const textResponse: string = await response.text();
  return {
    _type: '_HttpError',
    url: response.url,
    status: response.status,
    statusText: response.statusText,
    error: await extractJson(textResponse),
    errorAsString: textResponse,
    responseHeaders: responseHeaders
  };
}

export function isHttpError(error: any): error is HttpError {
  return error && error._type === '_HttpError';
}

export class HttpClient {
  public get<T extends object>(url: string, options?: HttpOptions): Observable<T> {
    return fromFetch(url, {
      ...options,
      method: 'GET',
      headers: {
        ...(options?.headers ?? {})
      }
    }).pipe(this._handleResponse<T>());
  }

  public getMapped<T extends object>(url: string, mappers: Mappers<T>, options?: HttpOptions): Observable<T> {
    return fromFetch(url, {
      ...options,
      method: 'GET',
      headers: {
        ...(options?.headers ?? {})
      }
    }).pipe(this._handleResponse<T>(mappers));
  }

  public getAsText(url: string, options?: HttpOptions): Observable<string> {
    return fromFetch(url, {
      ...options,
      method: 'GET',
      headers: {
        ...(options?.headers ?? {})
      }
    }).pipe(this._handleTextResponse());
  }

  public post<T>(url: string, body: FormData, options?: HttpOptions): Observable<T>;
  public post<T>(url: string, body: object, options?: HttpOptions): Observable<T>;
  public post<T>(url: string, body: object | FormData, options?: HttpOptions): Observable<T> {
    if (body instanceof FormData) {
      return fromFetch(url, {
        ...options,
        method: 'POST',
        body: body,
        headers: {
          ...(options?.headers ?? {})
        }
      }).pipe(this._handleResponse<T>());
    }

    return fromFetch(url, {
      ...options,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.headers ?? {})
      },
      body: JSON.stringify(body)
    }).pipe(this._handleResponse<T>());
  }

  public put<T>(url: string, body: FormData, options?: HttpOptions): Observable<T>;
  public put<T>(url: string, body: object, options?: HttpOptions): Observable<T>;
  public put<T>(url: string, body: object | FormData, options?: HttpOptions): Observable<T> {
    if (body instanceof FormData) {
      return fromFetch(url, {
        ...options,
        method: 'PUT',
        body: body,
        headers: {
          ...(options?.headers ?? {})
        }
      }).pipe(this._handleResponse<T>());
    }

    return fromFetch(url, {
      ...options,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.headers ?? {})
      },
      body: JSON.stringify(body)
    }).pipe(this._handleResponse<T>());
  }

  public options<T>(url: string, body: object | undefined = undefined, options?: HttpOptions): Observable<T> {
    return fromFetch(url, {
      ...options,
      method: 'Options',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.headers ?? {})
      },
      body: body ? JSON.stringify(body) : undefined
    }).pipe(this._handleResponse<T>());
  }

  private _handleResponse<T>(mappers?: Mappers<T>): OperatorFunction<Response, T> {
    return switchMap((response: Response) => {
      if (response.ok) {
        return from(response.json()).pipe(
          map((json: any) => {
            if (mappers) {
              return mappers[0](json);
            }
            return json;
          })
        );
      } else {
        return from(createHttpError(response)).pipe(
          mergeMap((error: HttpError) => {
            return throwError(() => error);
          })
        );
      }
    });
  }

  private _handleTextResponse(): OperatorFunction<Response, string> {
    return switchMap((response: Response) => {
      if (response.ok) {
        return response.text();
      } else {
        return from(createHttpError(response)).pipe(
          mergeMap((error: HttpError) => {
            return throwError(() => error);
          })
        );
      }
    });
  }
}

export function provideHttpClient(): HttpClient {
  return provideSingleton(HttpClient, () => new HttpClient());
}

import { DeviceKind } from '@bcflit-env/types';
import { CmsActionButton } from './cms-action-button';

export type CmsPromotionSlide = {
  readonly title: string;
  readonly text: string;
  readonly imageUrl: string;
  readonly actionButton: CmsActionButton;
  readonly campaignId: string | undefined;
};

function createActionButton(json: Record<string, any>): CmsActionButton {
  return {
    text: json['label'],
    kind: json['type'],
    url: json['btnURL'] ?? undefined
  };
}

export function createCmsPromotionSlidesFromHttpData(
  httpData: Record<string, any>,
  deviceKind: DeviceKind
): CmsPromotionSlide[] {
  const container: CmsPromotionSlide[] = [];
  for (const data of httpData['data']) {
    const promotionSlide: CmsPromotionSlide = {
      title: data['attributes']['sliderContent']['title'],
      text: data['attributes']['sliderContent']['content'],
      imageUrl:
        deviceKind === 'mobile'
          ? data['attributes']['imageMobile']['data']['attributes']['url']
          : data['attributes']['image']['data']['attributes']['url'],
      actionButton: createActionButton(data['attributes']['buttons'][0]),
      campaignId:
        data['attributes']['campaignId'] && data['attributes']['campaignId'].length > 0
          ? data['attributes']['campaignId']
          : undefined
    };
    container.push(promotionSlide);
  }
  return container;
}

export type CmsBonus = {
  readonly title: string;
  readonly bonusId: string | undefined;
  readonly campaignId: string | undefined;
  readonly category: string | undefined;
  readonly imageUrl: string;
  readonly termsHtml: string | undefined;
  readonly termsWithDepositLink: boolean;
};

export function createCmsBonusesFromHttpData(httpData: [Record<string, any>]): CmsBonus[] {
  const container: CmsBonus[] = [];
  for (const data of httpData) {
    for (const innerData of data['subBonuses']) {
      const cmsBonus: CmsBonus = {
        title: innerData['title'],
        bonusId: innerData['bonusId'] ?? undefined,
        campaignId: data['campaignId'] ?? undefined,
        category: innerData['category'],
        imageUrl: innerData['image'],
        termsHtml: data['longTerms'],
        termsWithDepositLink: false
      };
      container.push(cmsBonus);
    }
  }
  return container;
}

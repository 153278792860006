import { mergeDeepRight } from 'rambdax-v11';
import { Observable, OperatorFunction, pipe, UnaryFunction } from 'rxjs';
import { filter, map, scan } from 'rxjs/operators';

export const filterIsTruthy = <T>(): UnaryFunction<Observable<T>, Observable<NonNullable<T>>> =>
  pipe(
    filter<T>((value: T) => Boolean<T>(value) || (value as any) === 0),
    map((value: T) => value as NonNullable<T>)
  );

export const scanMergeDeepObj: OperatorFunction<Record<string, any>, Record<string, any>> = scan(
  (acc: Record<string, any>, current: Record<string, any>) => mergeDeepRight(acc, current),
  {}
);

// use this if you use File object, R.mergeDeepRight uses JSON.stringify sa FILE is  stripped
export const scanMergeSimpleObj: OperatorFunction<Record<string, any>, Record<string, any>> = scan(
  (acc: Record<string, any>, current: Record<string, any>) => ({ ...acc, ...current }),
  {}
);

export const flattenCombineLatestWithLatestFrom21 = <O1, O2, O3>(): OperatorFunction<[[O1, O2], O3], [O1, O2, O3]> =>
  map((array: [[O1, O2], O3]) => [...array[0], array[1]]);

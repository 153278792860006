import { html, LitElement, TemplateResult } from 'lit';

export type ImportedElement = typeof BcfLitElement;

type HtmlWithImportedElementsConfig = {
  id?: string;
  importedElements?: ImportedElement[];
  html: TemplateResult;
};

function registerElements(importedElements: ImportedElement[]): void {
  for (const elementToDefine of importedElements) {
    registerElement(elementToDefine);
  }
}

export function registerElement(elementToDefine: ImportedElement): void {
  if (elementToDefine.elementSelector.length === 0) {
    console.error('you need to define elementSelector property on TmLitElement class', elementToDefine);
    return;
  }
  if (!customElements.get(elementToDefine.elementSelector)) {
    customElements.define(elementToDefine.elementSelector, elementToDefine as unknown as CustomElementConstructor);
  }
}

export function htmlWithImportedElements(config: HtmlWithImportedElementsConfig): TemplateResult {
  registerElements(config.importedElements ?? []);

  const commentStart: Comment | undefined = config.id
    ? document.createComment(`partial-id-start: ${config.id}`)
    : undefined;
  const commentEnd: Comment | undefined = config.id
    ? document.createComment(`partial-id-end: ${config.id}`)
    : undefined;
  return html` ${commentStart ? commentStart : ''} ${config.html} ${commentEnd ? commentEnd : ''}`;
}

export abstract class BcfLitElement extends LitElement {
  protected _importedElements: ImportedElement[] = [];
  public static elementSelector: string = '';

  public override connectedCallback(): void {
    super.connectedCallback();
    registerElements(this._importedElements);
  }
}

export type CmsStaticSupportTopic = {
  readonly topic: string;
  readonly category: string;
  readonly answerHtml: string;
};

export type CmsStaticSupport = {
  readonly faqTopics: CmsStaticSupportTopic[];
  readonly workingHours: string;
  readonly email: string;
  readonly address: string;
  readonly phone: string;
};

export function createCmsStaticSupportFromHttpData(httpData: Record<string, any>): CmsStaticSupport {
  const faqTopics: CmsStaticSupportTopic[] = [];

  for (const inner of httpData['faq'] ?? []) {
    const category: string = inner['title'];
    for (const inner2 of inner['items']) {
      const topic: CmsStaticSupportTopic = {
        topic: inner2['question'],
        category: category,
        answerHtml: inner2['answer']
      };
      faqTopics.push(topic);
    }
  }

  return {
    faqTopics: faqTopics,
    workingHours: httpData['workingHours'],
    email: httpData['email'],
    address: httpData['address'],
    phone: httpData['phone']
  };
}

import { AppId } from 'configs/app-id';

function stringifyPayload(bodyPayload: Record<string, any>): string {
  try {
    return JSON.stringify(bodyPayload);
  } catch (err) {
    const newPayload: Record<string, any> = {
      ...bodyPayload,
      rawError: undefined
    };
    return JSON.stringify(newPayload);
  }
}

async function sendError(
  appId: AppId,
  releaseVersion: string,
  error: Error,
  url: string | undefined,
  cookies: Record<string, string>
): Promise<void> {
  const bodyPayload: Record<string, any> = {
    appId: appId,
    version: releaseVersion,
    url: url,
    error: error,
    cookies: cookies
  };

  const crashUrl: string = `https://crash-logger-dot-cms-headless-gae.ew.r.appspot.com/tmf-error-reporting/`;
  // const crashUrl: string = `http://localhost:3005/tmf-error-reporting/`;
  await fetch(crashUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: stringifyPayload(bodyPayload)
  });
  return undefined;
}

function getCorrectError(error: Error): Error {
  const stringified: string = JSON.stringify(error);
  if (stringified === '{}' && error.message) {
    return {
      name: error.name,
      message: error.message,
      stack: error.stack
    };
  }
  return error;
}

export async function sendToErrorReporting(
  appId: AppId,
  releaseVersion: string,
  error: Error | undefined,
  url: string | undefined,
  cookies: Record<string, string>
): Promise<void> {
  try {
    if (!error) {
      return;
    }
    if (error.stack?.includes('googletagmanager')) {
      return;
    }
    if (error.stack?.includes('rezync.com')) {
      return;
    }
    if (error && error.name === 'HttpDismissError') {
      return;
    }
    sendError(appId, releaseVersion, getCorrectError(error), url, cookies);
  } catch (err) {}
}

export async function sendToErrorUnhandledReporting(
  appId: AppId,
  releaseVersion: string,
  error: Error | undefined,
  url: string | undefined,
  cookies: Record<string, string>
): Promise<void> {
  try {
    if (!error) {
      return;
    }
    sendError(appId, releaseVersion, error, url, cookies);
  } catch (err) {}
}

import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { mapToString } from '@tmf-shared-misc/rx-operators/map-to-string';
import { ActivatedRoute } from '@tmf-shared-platform/activated-route/activated-route';
import { sharedSettings, SharedSettings } from '@tmp-shared-settings/settings/shared-settings';
import { pluck } from 'rxjs';
import { AffiliateDataMemory, provideAffiliateDataMemory } from './affiliate-data-memory';

export class AffiliatesDataCatcher {
  constructor(
    private _sharedSettings: SharedSettings,
    private _affiliateDataMemory: AffiliateDataMemory
  ) {}

  public init(activatedRoute: ActivatedRoute): void {
    if (this._sharedSettings.env.affiliateIdQueryParamName) {
      activatedRoute.queryParams$
        .pipe(pluck(this._sharedSettings.env.affiliateIdQueryParamName!), mapToString())
        .subscribe((affiliateId: string | undefined) => this._affiliateDataMemory.updateDataRaw(affiliateId));
    }
  }
}

export function provideAffiliatesDataCatcher(): AffiliatesDataCatcher {
  return provideSingleton(
    AffiliatesDataCatcher,
    () => new AffiliatesDataCatcher(sharedSettings, provideAffiliateDataMemory())
  );
}

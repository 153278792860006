import { createHttpError } from '@bcf-vanilla-ts-v1-api-access/common/http-client';

export class HttpClient {
  public async get<T>(url: string): Promise<T> {
    const response: Response = await fetch(url);

    if (response.ok) {
      return response.json();
    } else {
      throw createHttpError(response);
    }
  }

  public async post<T, R>(url: string, body: R): Promise<T> {
    const response: Response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    if (response.ok) {
      return await response.json();
    } else {
      throw await createHttpError(response);
    }
  }
}

export const httpClient: HttpClient = new HttpClient();

import { createContext } from '@lit/context';
import { Routes } from '@tmf-shared-platform/router/internal';
import { auditTime, BehaviorSubject, Observable, Subject } from 'rxjs';

export type Params = {
  [key: string]: string | undefined;
};

export type QueryParams = {
  [key: string]: string | number | boolean | undefined;
};

export class ActivatedRoute {
  private _routes: Routes[] = [];
  private _currentParams: Params | undefined;
  private _currentFragment: string | undefined;
  private _currentRaqQueryParamsString: string | undefined;

  private _params$: BehaviorSubject<Params> = new BehaviorSubject<Params>({});
  private _fragment$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  private _queryParams$: BehaviorSubject<QueryParams> = new BehaviorSubject<QueryParams>({});
  private _popState$: Subject<void> = new Subject<void>();

  private _doCheckAtNextTick$: Subject<void> = new Subject<void>();

  constructor() {
    window.addEventListener('click', () => this._doCheckAtNextTick$.next(undefined));
    window.addEventListener('popstate', () => {
      this._doCheckAtNextTick$.next(undefined);
      this._popState$.next(undefined);
    });
    window.addEventListener('hashchange', () => this._doCheckAtNextTick$.next(undefined));
    window.addEventListener('routerUpdated', () => this._doCheckAtNextTick$.next(undefined));
    this._checkAtNextTick();
  }

  public addRouter(routes: Routes): void {
    this._routes = [...this._routes, routes];
  }

  public removeRouter(routes: Routes): void {
    this._routes = this._routes.filter((innerRoutes: Routes) => innerRoutes !== routes);
  }

  public get params$(): Observable<Params> {
    return this._params$.asObservable();
  }

  public get queryParams$(): Observable<QueryParams> {
    return this._queryParams$.pipe(auditTime(20)); // this audit time must be grater the checkAtNextTick
  }

  public get popState$(): Observable<void> {
    return this._popState$;
  }

  public getQueryParamsTyped<T>(): Observable<T> {
    return this.queryParams$ as Observable<T>;
  }

  public get fragment$(): Observable<string | undefined> {
    return this._fragment$.asObservable();
  }

  private get _lastRouter(): Routes | undefined {
    return this._routes[this._routes.length - 1];
  }

  private _checkAtNextTick(): void {
    this._doCheckAtNextTick$.pipe(auditTime(10)).subscribe(() => {
      this._checkParams();
      this._checkFragment();
      this._checkQueryParams();
    });
  }

  private _checkParams(): void {
    if (this._lastRouter && this._currentParams !== this._lastRouter.params) {
      this._params$.next(this._lastRouter!.params);
      this._currentParams = this._lastRouter.params;
    }
  }

  private _checkFragment(): void {
    const currentFragment: string | undefined =
      window.location.hash.length > 0 ? window.location.hash.slice(1) : undefined;
    if (this._currentFragment !== currentFragment) {
      this._fragment$.next(currentFragment);
      this._currentFragment = currentFragment;
    }
  }

  private _checkQueryParams(): void {
    if (this._currentRaqQueryParamsString !== window.location.search) {
      const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
      const queryParams: QueryParams = {};
      for (const [key, value] of urlSearchParams.entries()) {
        queryParams[key] = value;
        if (value === 'true') {
          queryParams[key] = true;
        }
        if (queryParams[key] === 'false') {
          queryParams[key] = false;
        }
        if (!isNaN(Number(value))) {
          queryParams[key] = Number(value);
        }
      }
      this._queryParams$.next(queryParams);
      this._currentRaqQueryParamsString = window.location.search;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/typedef
export const activatedRouteContext = createContext<ActivatedRoute>('activatedRoute');

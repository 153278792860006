import { createContext } from '@lit/context';
import { ActivatedRoute } from '@tmf-shared-platform/activated-route/activated-route';
import { distinctUntilChanged, map, Observable } from 'rxjs';

export class ActivatedModal {
  constructor(private _activatedRoute: ActivatedRoute) {}

  public get modal$(): Observable<string | undefined> {
    return this._activatedRoute.fragment$.pipe(
      map((fragment: string | undefined) =>
        fragment?.includes('modal-') ? fragment.replace('modal-', '') : undefined
      ),
      map((modalNameWithParams: string | undefined) => modalNameWithParams?.split('$')[0])
    );
  }

  public get param(): Observable<string | undefined> {
    return this._activatedRoute.fragment$.pipe(
      map((fragment: string | undefined) =>
        fragment?.startsWith('modal-') ? (fragment.split('$')?.[1] ?? undefined) : undefined
      ),
      distinctUntilChanged()
    );
  }

  public get params(): Observable<string[] | undefined> {
    return this.param.pipe(
      map((param: string | undefined) => param?.split('~')),
      distinctUntilChanged()
    );
  }
}

// eslint-disable-next-line @typescript-eslint/typedef
export const activatedModalContext = createContext<ActivatedModal>('activatedModal');

import { LocalStorageKeys } from './enums';

export class LocalStorage {
  public clear(): void {
    localStorage.clear();
  }

  public getString(key: LocalStorageKeys): string | undefined {
    return localStorage.getItem(key) ?? undefined;
  }

  public setString(key: LocalStorageKeys, value: string): void {
    return localStorage.setItem(key, value);
  }

  public getNumber(key: LocalStorageKeys): number {
    return Number(localStorage.getItem(key));
  }

  public setNumber(key: LocalStorageKeys, value: number): void {
    localStorage.setItem(key, value?.toString());
  }

  public getBoolean(key: LocalStorageKeys): boolean {
    return localStorage.getItem(key) === 'true';
  }

  public setBoolean(key: LocalStorageKeys, value: boolean): void {
    localStorage.setItem(key, value.toString());
  }

  public getJson<T>(key: LocalStorageKeys): T | undefined {
    const value: string | null = localStorage.getItem(key);
    return value ? JSON.parse(value) : undefined;
  }

  public setJson<T>(key: LocalStorageKeys, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public remove(key: LocalStorageKeys): void {
    localStorage.removeItem(key);
  }
}

import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import {
  CookiesConsentManager,
  provideCookiesConsentManager
} from '@bcflit-v1-ui-shared/cookies-consent/cookies-consent-manager';
import { GtmService, provideGtmService } from './gtm.service';
import { GtmCookieConsent } from './types';

export class GtmNotifierService {
  constructor(
    private _cookiesConsentManager: CookiesConsentManager,
    private _gtmService: GtmService
  ) {}

  public init(): void {
    this._cookiesConsentManager.getAnalyticsConsent().subscribe((consent: GtmCookieConsent) => {
      this._gtmService.gtag('consent', 'update', consent);
      this._gtmService.pushDataLayer('cookie-consent-update');
    });
  }
}

export function provideGtmNotifierService(): GtmNotifierService {
  return provideSingleton(
    GtmNotifierService,
    () => new GtmNotifierService(provideCookiesConsentManager(), provideGtmService())
  );
}

import { createContext } from '@lit/context';

type LocationKind = {
  origin: string;
  pathname: string;
  search: string;
  hash: string;
};

function mapToFullPath(location: LocationKind): string {
  return `${location.pathname}${location.search}${location.hash}`;
}

export class HistoryUrl {
  private _history: string[] = [];

  constructor() {
    window.addEventListener('popstate', () => this._popState());
    window.addEventListener('hashchange', () => this._checkAtNextTick());
    window.addEventListener('routerUpdated', () => this._checkAtNextTick());
    this._history = this._getFromMemory();
  }

  private _checkAtNextTick(): void {
    setTimeout(() => {
      this._checkUrl();
    }, 10);
  }

  private _checkUrl(): void {
    const newPath: string = mapToFullPath(window.location);
    const lastHistoryUrl: string | undefined = this._history[this._history.length - 1];
    if (lastHistoryUrl === newPath) {
      return;
    }
    this._history.push(newPath);
    this._updateMemory();
  }

  private _popState(): void {
    this._history = this._history.slice(0, -1);
    this._updateMemory();
  }

  public get history(): string[] {
    return this._history.slice();
  }

  private _updateMemory(): void {
    window.sessionStorage.setItem('historyUrls', JSON.stringify(this.history));
  }

  private _getFromMemory(): string[] {
    const fromMemory: string | undefined = window.sessionStorage.getItem('historyUrls') ?? undefined;
    if (fromMemory) {
      return JSON.parse(fromMemory);
    }
    return [];
  }
}

// eslint-disable-next-line @typescript-eslint/typedef
export const historyUrlContext = createContext<HistoryUrl>('historyUrl');

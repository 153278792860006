export function extractCookies(cookiesStr: string): Record<string, string> {
  const container: Record<string, string> = {};

  const splitedOuter: string[] = cookiesStr.split(';');
  for (const innerStr of splitedOuter) {
    const splitedInner: string[] = innerStr.split('=');
    container[splitedInner[0].trim()] = splitedInner[1];
  }

  return container;
}

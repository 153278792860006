import { UserSyndicateSession } from '@tmf-shared-models/user-syndicate-session';

export function mapToTotalSyndicatesAmountShares(syndicateSessions: UserSyndicateSession[] | undefined): number {
  if (!syndicateSessions) {
    return 0;
  }
  let amount: number = 0;
  for (const session of syndicateSessions) {
    for (const participant of session.participants) {
      amount += participant.amount;
    }
  }
  return amount;
}

import { ReactiveController, ReactiveControllerHost } from 'lit';
import { Subscription, SubscriptionLike } from 'rxjs';
import { Bloc } from './bloc';

export abstract class UnsubscribableController<T> implements ReactiveController {
  private _subscriptions: Subscription = new Subscription();

  constructor(
    protected _host: ReactiveControllerHost,
    protected _bloc?: Bloc
  ) {
    this._host.addController(this);
  }

  protected set _sub(sub: SubscriptionLike | undefined) {
    if (Boolean(sub)) {
      this._subscriptions.add(sub);
    }
  }

  public hostDisconnected(): void {
    this._subscriptions.unsubscribe();
    this._bloc?.destroy();
  }

  protected _set<K extends keyof T>(key: K) {
    return <V extends T[K]>(value: V): void => {
      const that: T = this as any as T;
      that[key] = value;
      this._host.requestUpdate();
    };
  }
}

import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { LocalStorageKeys } from '@tmf-shared-platform/local-storage/enums';
import { LocalStorage } from '@tmf-shared-platform/local-storage/localstorage';
import { addDays, isBefore } from 'date-fns';

type AffiliateDataRaw = string | undefined;

type AffiliateDataInMemory = {
  affData: AffiliateDataRaw;
  createdAt: Date;
};

export class AffiliateDataMemory {
  constructor(private _localStorage: LocalStorage) {}

  public getDataRawFromMemory(): AffiliateDataRaw | undefined {
    const affiliateData: AffiliateDataInMemory | undefined = this._localStorage.getJson<AffiliateDataInMemory>(
      LocalStorageKeys.affiliateData
    );
    if (!affiliateData) {
      return undefined;
    }
    if (this._isActiveInMemory(affiliateData)) {
      return affiliateData.affData;
    }
    return undefined;
  }

  public updateDataRaw(affiliateData: AffiliateDataRaw | undefined): void {
    if (!affiliateData) {
      return;
    }
    const affiliateDataFromStorage: AffiliateDataInMemory | undefined =
      this._localStorage.getJson<AffiliateDataInMemory>(LocalStorageKeys.affiliateData);
    if (this._isActiveInMemory(affiliateDataFromStorage)) {
      return;
    }
    const inMemory: AffiliateDataInMemory = {
      affData: affiliateData,
      createdAt: new Date()
    };
    this._localStorage.setJson(LocalStorageKeys.affiliateData, inMemory);
  }

  private _isActiveInMemory(affiliateData: AffiliateDataInMemory | undefined): boolean {
    if (affiliateData && isBefore(new Date(), addDays(affiliateData.createdAt, 60))) {
      return true;
    }
    return false;
  }
}
export function provideAffiliateDataMemory(): AffiliateDataMemory {
  return provideSingleton(AffiliateDataMemory, () => new AffiliateDataMemory(new LocalStorage()));
}

import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { GtmCookieConsent } from '@bcflit-v1-ui-shared/gtm/types';
import { consentTypesMap } from '@bcflit-v1-ui-shared/gtm/types-mapper';
import { Observable, ReplaySubject } from 'rxjs';
import { CookiesConsentType } from './cookies-consent-types';

export class CookiesConsentManager {
  private _analyticsConsent$: ReplaySubject<GtmCookieConsent> = new ReplaySubject<GtmCookieConsent>(1);

  public set(currentConsent: CookiesConsentType[]): void {
    this._analyticsConsent$.next(consentTypesMap(currentConsent));
  }

  public getAnalyticsConsent(): Observable<GtmCookieConsent> {
    return this._analyticsConsent$;
  }
}

export function provideCookiesConsentManager(): CookiesConsentManager {
  return provideSingleton(CookiesConsentManager, () => new CookiesConsentManager());
}

import { parseRawError } from './parse-raw-error';

export class ParseJsonError implements Error {
  public name: string = 'ParseJsonError';

  constructor(
    public message: string,
    public rawJsonFromHttp: Record<string, any>,
    public rawJsonFromHttpAsString: string,
    public rawError: unknown,
    public stack?: string
  ) {}

  public static create(modelName: string, rawJson: Record<string, any>, rawError: unknown): ParseJsonError {
    const parsedError: Error = parseRawError(rawError);
    return new ParseJsonError(
      `cannot parse Json for model: ${modelName}`,
      rawJson,
      JSON.stringify(rawJson),
      parsedError,
      parsedError?.stack
    );
  }
}

import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { device } from '@bcflit-device';
import {
  BottomSheetCookiesConsentService,
  provideBottomSheetCookiesConsentService
} from '@bcflit-v1-ui-modals-shared/bottom-sheet-cookies-consent.service';
import {
  ModalCookiesConsentService,
  provideModalCookiesConsentService
} from '@bcflit-v1-ui-modals-shared/modal-cookies-consent.service';

function openCookiesConsent(
  modalCookiesConsentService: ModalCookiesConsentService,
  bottomSheetCookiesConsentService: BottomSheetCookiesConsentService
): void {
  if (device === 'desktop') {
    modalCookiesConsentService.show$.next(true);
  }
  if (device === 'mobile') {
    bottomSheetCookiesConsentService.show$.next(true);
  }
}

function closeCookiesConsent(
  modalCookiesConsentService: ModalCookiesConsentService,
  bottomSheetCookiesConsentService: BottomSheetCookiesConsentService
): void {
  if (device === 'desktop') {
    modalCookiesConsentService.show$.next(false);
  }
  if (device === 'mobile') {
    bottomSheetCookiesConsentService.show$.next(false);
  }
}

export class CookiesConsentOpenerService {
  constructor(
    private _modalCookiesConsentService: ModalCookiesConsentService,
    private _bottomSheetCookiesConsentService: BottomSheetCookiesConsentService
  ) {}

  public openCookiesConsent(): void {
    openCookiesConsent(this._modalCookiesConsentService, this._bottomSheetCookiesConsentService);
  }

  public closeCookiesConsent(): void {
    closeCookiesConsent(this._modalCookiesConsentService, this._bottomSheetCookiesConsentService);
  }
}

export function provideCookiesConsentOpenerService(): CookiesConsentOpenerService {
  return provideSingleton(
    CookiesConsentOpenerService,
    () =>
      new CookiesConsentOpenerService(provideModalCookiesConsentService(), provideBottomSheetCookiesConsentService())
  );
}

import { DeviceBaseType, DeviceKind } from './env/types';

export const deviceBaseType: DeviceBaseType = import.meta.env.VITE_DEVICE ?? 'mobile';
export const device: DeviceKind = mapDeviceToDeviceKind(deviceBaseType);

function mapDeviceToDeviceKind(deviceBaseType: DeviceBaseType): DeviceKind {
  switch (deviceBaseType) {
    case 'mobile-twa':
      return 'mobile';
    default:
      return deviceBaseType;
  }
}

/* eslint-disable */
import { TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import { TemplateResult, html } from 'lit';
import { property } from 'lit/decorators.js';

/** COPIED FROM BCF REPO */

export class GtmLoaderElement extends TmfLitElement {
  public static override elementSelector: string = 'tmf-jzm-gtm-loader';

  @property({ type: String })
  public gtmId?: string;

  private _gtmInitialized: boolean = false;

  public override connectedCallback(): void {
    super.connectedCallback();
    if (!this.gtmId) {
      return;
    }
    if (document.location.search.includes('gtm_debug')) {
      this._initGtm(this.gtmId);
      return;
    }
    window.addEventListener('click', () => this._initGtm(this.gtmId!), { once: true });
    window.addEventListener('touchstart', () => this._initGtm(this.gtmId!), { once: true });
    window.addEventListener('scroll', () => this._initGtm(this.gtmId!), { once: true });
    if (window.location.hash !== '') {
      this._initGtm(this.gtmId!);
    }
    setTimeout(() => {
      this._initGtm(this.gtmId!);
    }, 10000);
  }

  private _initGtm(gtmId: string): void {
    if (this._gtmInitialized) {
      return;
    }
    this._gtmInitialized = true;
    (function (window, document, scriptTag, dataLayerTag, gtmId, gtagTag, gtmlLazyLoaded) {
      const windowAny: any = window;
      windowAny[dataLayerTag] = windowAny[dataLayerTag] || [];
      windowAny[gtagTag] = function () {
        (window as any).dataLayer.push(arguments);
      };
      windowAny[gtagTag]('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
        personalization_storage: 'denied'
      });
      windowAny[dataLayerTag].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = document.getElementsByTagName(scriptTag)[0],
        j: HTMLScriptElement = document.createElement(scriptTag) as HTMLScriptElement,
        dl = dataLayerTag != 'dataLayer' ? '&l=' + dataLayerTag : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId + dl;
      j.onload = () => {
        windowAny[gtmlLazyLoaded] = true;
        windowAny.dispatchEvent(new CustomEvent('gtmLoaded'));
      };
      f.parentNode!.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtmId, 'gtag', 'isGtmLazyLoaded');
  }

  public override render(): TemplateResult {
    return html``;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    'tmf-jzm-gtm-loader': GtmLoaderElement;
  }
}

import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { ReplaySubject } from 'rxjs';
import { ModalKindStandalone } from './types';

export class ModalStandaloneResolverSignalService {
  private _alreadyResolved: ModalKindStandalone[] = [];

  public doLoadNewModal$: ReplaySubject<ModalKindStandalone> = new ReplaySubject<ModalKindStandalone>(); // replay without limits

  public resolve(kind: ModalKindStandalone) {
    return (state: boolean): void => {
      if (!this._alreadyResolved.includes(kind) && state) {
        this._alreadyResolved.push(kind);
        this.doLoadNewModal$.next(kind);
      }
    };
  }
}

export function provideModalStandaloneResolverSignalService(): ModalStandaloneResolverSignalService {
  return provideSingleton(ModalStandaloneResolverSignalService, () => new ModalStandaloneResolverSignalService());
}

import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { httpClient, HttpClient } from '../api/http-client';

export class ExtApi {
  constructor(public http: HttpClient) {}
}

export function provideExtApi(): ExtApi {
  return provideSingleton(ExtApi, () => new ExtApi(httpClient));
}

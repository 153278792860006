import { createContext } from '@lit/context';
import { Observable, ReplaySubject } from 'rxjs';
import { ActivatedLangConst, activatedLangConst } from './activated-lang-const';

export class ActivatedLang {
  private _currentLang$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _setuped: boolean = false;

  constructor(private _activatedLangConst: ActivatedLangConst) {}

  public setupCurrentLang(lang: string): void {
    if (this._setuped) {
      throw new Error('you can setup lang only once - lang work on refreshing');
    }
    this._setuped = true;
    this._activatedLangConst.setLang(lang);
    this._currentLang$.next(lang);
  }

  public get currentLang$(): Observable<string> {
    return this._currentLang$.asObservable();
  }
}

export const activatedLang: ActivatedLang = new ActivatedLang(activatedLangConst);

// eslint-disable-next-line @typescript-eslint/typedef
export const activatedLangContext = createContext<ActivatedLang>('activatedLang');

import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { filterIsTruthy } from '@tmf-shared-misc/rx-operators';
import {
  CookiesConsentOpenerService,
  provideCookiesConsentOpenerService
} from '@tmf-ui-casino-services/cookies-consent-opener.service';
import { filter } from 'rxjs';
import { CookiesConsent, provideCookiesConsent } from './cookies-consent';
import { CookiesConsentManager, provideCookiesConsentManager } from './cookies-consent-manager';
import { CookiesConsentType } from './cookies-consent-types';

export class CookiesConsentService {
  constructor(
    private _cookiesConsent: CookiesConsent,
    private _cookiesConsentManager: CookiesConsentManager,
    private _cookiesConsentOpenerService: CookiesConsentOpenerService
  ) {}

  public init(): void {
    this._cookiesConsent.acceptedCookiesConsent$
      .pipe(filterIsTruthy())
      .subscribe((currentConsent: CookiesConsentType[]) => this._cookiesConsentManager.set(currentConsent));

    this._cookiesConsent.acceptedCookiesConsent$
      .pipe(filter((cookiesConsent: CookiesConsentType[] | undefined) => !cookiesConsent))
      .subscribe(() => this._doNavigateToCookiesConsent());
  }

  private _doNavigateToCookiesConsent(): void {
    this._cookiesConsentOpenerService.openCookiesConsent();
  }
}

export function provideCookiesConsentService(): CookiesConsentService {
  return provideSingleton(
    CookiesConsentService,
    () =>
      new CookiesConsentService(
        provideCookiesConsent(),
        provideCookiesConsentManager(),
        provideCookiesConsentOpenerService()
      )
  );
}

export type CmsStaticGeneric = {
  readonly contentHtml: string;
  readonly version: string | undefined;
  readonly lastUpdate: Date | undefined;
  readonly effectiveFromDate: Date | undefined;
};

export function createCmsStaticGenericFromHttpData(httpData: Record<string, any>): CmsStaticGeneric {
  const mainContent: Record<string, any> | undefined =
    httpData?.['data']?.[0]?.['attributes']?.['content']?.[0]?.items?.[0];
  const metadata: Record<string, any> | undefined = httpData?.['data']?.[0]?.['attributes'];

  if (!mainContent || !metadata) {
    return {
      contentHtml: '',
      version: undefined,
      lastUpdate: undefined,
      effectiveFromDate: undefined
    };
  }

  return {
    contentHtml: mainContent['plainHTML'],
    version: mainContent['Version'],
    lastUpdate: metadata['updatedAt'] ? new Date(metadata['updatedAt']) : undefined,
    effectiveFromDate: undefined
  };
}

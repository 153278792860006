import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { BehaviorSubject } from 'rxjs';
import {
  ModalStandaloneResolverSignalService,
  provideModalStandaloneResolverSignalService
} from './modal-standalone-resolver-signal.service';

export class ModalCookiesConsentService {
  public show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _modalStandaloneResolverSignal: ModalStandaloneResolverSignalService) {
    this._init();
  }

  private _init(): void {
    this.show$.subscribe(this._modalStandaloneResolverSignal.resolve('cookies-consent'));
  }
}
export function provideModalCookiesConsentService(): ModalCookiesConsentService {
  return provideSingleton(
    ModalCookiesConsentService,
    () => new ModalCookiesConsentService(provideModalStandaloneResolverSignalService())
  );
}

export type CmsPromotion = {
  readonly slugName: string;
  readonly title: string;
  readonly imageUrl: string;
  readonly introText: string;
  readonly campaignId: string | undefined;
  readonly categories: string[];
  readonly startAt: Date;
  readonly endAt: Date;
};

export function createCmsPromotionsFromHttpData(httpData: Record<string, any>): CmsPromotion[] {
  const container: CmsPromotion[] = [];

  for (const item of httpData['data']) {
    const promotion: CmsPromotion = {
      slugName: item['attributes']['slugName'],
      title: item['attributes']['title'],
      imageUrl: item['attributes']['imageUrl']['data']['attributes']['url'],
      introText: item['attributes']['introText'],
      campaignId:
        item['attributes']['campaignId'] && item['attributes']['campaignId'].length > 0
          ? item['attributes']['campaignId']
          : undefined,
      categories: item['attributes']['categories']['data'].map(
        (data: Record<string, any>) => data['attributes']['categoryName']
      ),
      startAt: new Date(item['attributes']['startDate']),
      endAt: new Date(item['attributes']['endDate'])
    };
    container.push(promotion);
  }

  return container;
}

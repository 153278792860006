import { Observable, Subject, map, startWith } from 'rxjs';

export class HistoryState {
  private _historyStateChanged$: Subject<void> = new Subject<void>();

  public getState(): Observable<Record<string, any>> {
    return this._historyStateChanged$.pipe(
      startWith(undefined),
      map(() => window.history.state)
    );
  }

  public updateState(url: string, newState: Record<string, any>): void {
    const mergedState: Record<string, any> = {
      ...window.history.state,
      ...newState
    };
    window.history.replaceState(mergedState, '', url);
    this._historyStateChanged$.next(undefined);
  }

  public stateMightChanged(): void {
    this._historyStateChanged$.next(undefined);
  }
}

export const historyState: HistoryState = new HistoryState();

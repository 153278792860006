export class ExternalRedirectorService {
  public redirectToExternalUrl(url: string, openInNewTab: boolean = false): void {
    window.open(url, openInNewTab ? '_blank' : '_self');
  }

  public redirectIframeAsExternalUrl(iframeUrl: string): void {
    document.open();
    document.write(iframeUrl);
    document.close();
  }
}

export const externalRedirectorService: ExternalRedirectorService = new ExternalRedirectorService();

import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { transferRxNext } from '@tmf-shared-misc/rx-helpers';
import { Observable, ReplaySubject, from } from 'rxjs';
import { CookiesConsentMemory, provideCookiesConsentMemory } from './cookies-consent-memory';
import { CookiesConsentType } from './cookies-consent-types';

export class CookiesConsent {
  private _updateCookiesConsent$: ReplaySubject<CookiesConsentType[] | undefined> = new ReplaySubject<
    CookiesConsentType[] | undefined
  >();

  constructor(private _cookiesConsentMemory: CookiesConsentMemory) {
    this._init();
  }

  private _init(): void {
    from(this._cookiesConsentMemory.acceptedCookiesConsent()).subscribe(transferRxNext(this._updateCookiesConsent$));
  }

  public set(cookieConsent: CookiesConsentType[]): void {
    this._updateCookiesConsent$.next(cookieConsent);
    this._cookiesConsentMemory.setAcceptedCookiesConsent(cookieConsent);
  }

  public get acceptedCookiesConsent$(): Observable<CookiesConsentType[] | undefined> {
    return this._updateCookiesConsent$;
  }
}

export function provideCookiesConsent(): CookiesConsent {
  return provideSingleton(CookiesConsent, () => new CookiesConsent(provideCookiesConsentMemory()));
}

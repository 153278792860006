import { RouteConfig, Router } from '@tmf-shared-platform/router/internal';
import { html } from 'lit';

export function appRoutes(lazyRouter: () => Router, langs: string[]): RouteConfig[] {
  return [
    ...langs.map(
      (lang: string) =>
        ({
          path: `/${lang}{/*}?`,
          render: () => html`<tmf-lang-route .appLang="${lang}"></tmf-lang-route>`,
          enter: async () => {
            await import('./lang-route/lang-route.element');
            return true;
          }
        }) as RouteConfig
    )
  ];
}

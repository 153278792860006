import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { ReplaySubject } from 'rxjs';
import { BottomSheetKindStandalone } from './types';

export class BottomSheetStandaloneResolverSignalService {
  private _alreadyResolved: BottomSheetKindStandalone[] = [];

  public doLoadNewBottomSheet$: ReplaySubject<BottomSheetKindStandalone> =
    new ReplaySubject<BottomSheetKindStandalone>(); // replay without limits

  public resolve(kind: BottomSheetKindStandalone) {
    return (state: boolean): void => {
      if (!this._alreadyResolved.includes(kind) && state) {
        this._alreadyResolved.push(kind);
        this.doLoadNewBottomSheet$.next(kind);
      }
    };
  }
}

export function provideBottomSheetStandaloneResolverSignalService(): BottomSheetStandaloneResolverSignalService {
  return provideSingleton(
    BottomSheetStandaloneResolverSignalService,
    () => new BottomSheetStandaloneResolverSignalService()
  );
}

export type CmsGameSeoContent = {
  readonly descriptionHtml: string;
  readonly descriptionExtendedHtml: string | undefined;
};

export function createCmsGameSeoContentFromHttpData(httpData: Record<string, any>): CmsGameSeoContent {
  const dataJson: Record<string, any> | undefined = httpData['data']?.[0]?.['attributes'];
  if (!dataJson) {
    return {
      descriptionHtml: '',
      descriptionExtendedHtml: undefined
    };
  }
  return {
    descriptionHtml: dataJson['descriptionHtml'],
    descriptionExtendedHtml: dataJson['descriptionExtendedHtml']
  };
}

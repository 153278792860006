import { CmsApi, cmsApi } from '@tmf-logic-api-access/http/api/cms.api';
import { UserDataInfo, userDataInfo } from '@tmf-logic-auth/services/user-data-info';
import { CmsCountry } from '@tmf-shared-models/cms-country';
import { UserLocation } from '@tmf-shared-models/user-location';
import { Observable, combineLatest, map } from 'rxjs';

export class CheckCountryBlockage {
  constructor(
    private _cmsApi: CmsApi,
    private _userDataInfo: UserDataInfo
  ) {}

  public shouldShowBlockagePage(): Observable<boolean> {
    return combineLatest([this._cmsApi.getCmsBlockedCountries(), this._userDataInfo.userLocation$]).pipe(
      map(([blockedCountries, userLocation]: [CmsCountry[], UserLocation]) =>
        Boolean(blockedCountries.find((country: CmsCountry) => country.countryCode === userLocation.countryCode))
      )
    );
  }
}

export const checkCountryBlockage: CheckCountryBlockage = new CheckCountryBlockage(cmsApi, userDataInfo);

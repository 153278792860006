import { DomainSettings, DomainSettingsCountry } from '@tmf-shared-models/domain-settings';
import { UserLocation } from '@tmf-shared-models/user-location';

export function mapToDomainSettingsCountry([userLocation, currentLang, domainSettings]: [
  UserLocation,
  string,
  DomainSettings
]): DomainSettingsCountry | undefined {
  return (
    domainSettings.settings?.[userLocation.countryCode.toUpperCase()] ??
    domainSettings.settings?.[currentLang.toUpperCase()]
  );
}

import { BehaviorSubject, lastValueFrom, Observable, ReplaySubject, Subject, take } from 'rxjs';

export function transferRxNext<T>(stream$: Subject<T> | BehaviorSubject<T> | ReplaySubject<T>) {
  return (value: T): void => stream$.next(value);
}

export function transferEventDetailsRxNext<T>(stream$: Subject<T> | BehaviorSubject<T> | ReplaySubject<T>) {
  return (value: CustomEvent<T>): void => stream$.next(value.detail);
}

export function transferCustomEventDetailRxNext<T>(stream$: Subject<T> | BehaviorSubject<T> | ReplaySubject<T>) {
  return (event: CustomEvent<T>): void => stream$.next(event.detail);
}

export function rxDone(stream$: Subject<void>): void {
  stream$.next(undefined);
  stream$.complete();
}

export function asPromise<T>(stream$: Observable<T>): Promise<T> {
  return lastValueFrom(stream$.pipe(take(1)));
}

import { cmsApi, CmsApi } from '@tmf-logic-api-access/http/api/cms.api';
import { cmsSeoSettingsForGame, CmsSeoSettingsForGame } from '@tmf-logic-cms/services/cms-seo-settings-for-game';
import { Bloc } from '@tmf-shared-classes/bloc';
import { withLatestFromWaitable } from '@tmf-shared-custom-rx-operators/with-latest-from-waitable';
import { filterIsTruthy } from '@tmf-shared-misc/rx-operators';
import { Game } from '@tmf-shared-models/brand-game';
import { CmsSeoSettings, CmsSeoSettingsConfig } from '@tmf-shared-models/cms-seo-settings';
import { DomainSettings } from '@tmf-shared-models/domain-settings';
import {
  DomainSettingsInfo,
  provideDomainSettingsInfo
} from '@tmf-shared-platform/domain-settings/domain-settings-info';
import { BehaviorSubject, combineLatest, from, map, Observable, ReplaySubject, shareReplay } from 'rxjs';
import { mapToCanonicalUrl } from './pure-utils/map-to-canonical-url';
import { mapToCanonicalUrlStriped } from './pure-utils/map-to-canonical-url-striped';
import { mapToCasinoGameSeoTitle } from './pure-utils/map-to-casino-game-seo-title';

export class CmsSeoMetaUpdaterBloc extends Bloc {
  private _metaForUrlShareRef$!: Observable<CmsSeoSettingsConfig | undefined>;

  public currentUrl$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public currentFullUrl$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public currentHostname$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public currentOpenGame$: BehaviorSubject<Game | undefined> = new BehaviorSubject<Game | undefined>(undefined);

  constructor(
    private _cmsApi: CmsApi,
    private _cmsSeoSettingsForGame: CmsSeoSettingsForGame,
    private _domainSettingsInfo: DomainSettingsInfo
  ) {
    super();
  }

  public getMetaTitle(): Observable<string> {
    return combineLatest([
      this._getMetaForUrl(),
      this._getCmsSeoSettings(),
      this._cmsSeoSettingsForGame.getSeoSettingsConfig(),
      this.currentHostname$,
      this.currentOpenGame$
    ]).pipe(
      map(
        ([settingsPerUrl, settings, settingsForGame, currentHostname, currentOpenGame]: [
          CmsSeoSettingsConfig | undefined,
          CmsSeoSettings,
          CmsSeoSettingsConfig | undefined,
          string,
          Game | undefined
        ]) =>
          settingsForGame?.metaTitle ??
          settingsPerUrl?.metaTitle ??
          mapToCasinoGameSeoTitle(currentOpenGame, currentHostname) ??
          settings.default.metaTitle ??
          ''
      )
    );
  }

  public getMetaDescription(): Observable<string> {
    return combineLatest([
      this._getMetaForUrl(),
      this._getCmsSeoSettings(),
      this._cmsSeoSettingsForGame.getSeoSettingsConfig()
    ]).pipe(
      map(
        ([settingsPerUrl, settings, settingsForGame]: [
          CmsSeoSettingsConfig | undefined,
          CmsSeoSettings,
          CmsSeoSettingsConfig | undefined
        ]) =>
          settingsForGame?.metaDescription ?? settingsPerUrl?.metaDescription ?? settings.default.metaDescription ?? ''
      )
    );
  }

  public getMetaRobots(): Observable<string> {
    return combineLatest([
      this._getMetaForUrl(),
      this._getCmsSeoSettings(),
      this._cmsSeoSettingsForGame.getSeoSettingsConfig()
    ]).pipe(
      map(
        ([settingsPerUrl, settings, settingsForGame]: [
          CmsSeoSettingsConfig | undefined,
          CmsSeoSettings,
          CmsSeoSettingsConfig | undefined
        ]) => settingsForGame?.metaRobots ?? settingsPerUrl?.metaRobots ?? settings.default.metaRobots ?? ''
      )
    );
  }

  public getMetaCanonical(): Observable<string | undefined> {
    // this return pathname always
    return combineLatest([this._getMetaForUrl(), this._cmsSeoSettingsForGame.getSeoSettingsConfig()]).pipe(
      map(mapToCanonicalUrl),
      map(mapToCanonicalUrlStriped)
    );
  }

  public getDomainSettings(): Observable<DomainSettings> {
    return this._domainSettingsInfo.getDomainSettings();
  }

  private _getMetaForUrl(): Observable<CmsSeoSettingsConfig | undefined> {
    return (this._metaForUrlShareRef$ ??= this.currentUrl$.pipe(
      withLatestFromWaitable(this._getCmsSeoSettings()),
      map(([url, settings]: [string, CmsSeoSettings]) => {
        return settings.perUrl[url];
      }),
      shareReplay(1)
    ));
  }

  private _getCmsSeoSettings(): Observable<CmsSeoSettings> {
    return from(this._cmsApi.getCmsSeoSettings()).pipe(filterIsTruthy());
  }
}

export function provideCmsSeoMetaUpdaterBloc(): CmsSeoMetaUpdaterBloc {
  return new CmsSeoMetaUpdaterBloc(cmsApi, cmsSeoSettingsForGame, provideDomainSettingsInfo());
}

export type MetaDefinition = {
  charset?: string;
  content?: string;
  httpEquiv?: string;
  id?: string;
  itemprop?: string;
  name?: string;
  property?: string;
  scheme?: string;
  url?: string;
} & {
  // TODO(IgorMinar): this type looks wrong
  [prop: string]: string;
};

export class MetaService {
  public addTag(tag: MetaDefinition, forceCreation: boolean = false): HTMLMetaElement | null {
    if (!tag) return null;
    return this._getOrCreateElement(tag, forceCreation);
  }

  public addTags(tags: MetaDefinition[], forceCreation: boolean = false): HTMLMetaElement[] {
    if (!tags) return [];
    return tags.reduce((result: HTMLMetaElement[], tag: MetaDefinition) => {
      if (tag) {
        result.push(this._getOrCreateElement(tag, forceCreation));
      }
      return result;
    }, []);
  }

  public getTag(attrSelector: string): HTMLMetaElement | null {
    if (!attrSelector) return null;
    return document.querySelector(`meta[${attrSelector}]`) || null;
  }

  public getTags(attrSelector: string): HTMLMetaElement[] {
    if (!attrSelector) return [];
    const list: NodeListOf<Element> = document.querySelectorAll(`meta[${attrSelector}]`);
    return list ? [].slice.call(list) : [];
  }

  public updateTag(tag: MetaDefinition, selector?: string): HTMLMetaElement | null {
    if (!tag) return null;
    selector = selector || this._parseSelector(tag);
    const meta: HTMLMetaElement = this.getTag(selector)!;
    if (meta) {
      return this._setMetaElementAttributes(tag, meta);
    }
    return this._getOrCreateElement(tag, true);
  }

  public removeTag(attrSelector: string): void {
    this.removeTagElement(this.getTag(attrSelector)!);
  }

  public removeTagElement(meta: HTMLMetaElement): void {
    if (meta) {
      meta.remove();
    }
  }

  private _getOrCreateElement(meta: MetaDefinition, forceCreation: boolean = false): HTMLMetaElement {
    if (!forceCreation) {
      const selector: string = this._parseSelector(meta);
      // It's allowed to have multiple elements with the same name so it's not enough to
      // just check that element with the same name already present on the page. We also need to
      // check if element has tag attributes
      const elem: HTMLMetaElement = this.getTags(selector).filter((elem: HTMLMetaElement) =>
        this._containsAttributes(meta, elem)
      )[0];
      if (elem !== undefined) return elem;
    }
    const element: HTMLMetaElement = document.createElement('meta') as HTMLMetaElement;
    this._setMetaElementAttributes(meta, element);
    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    head.appendChild(element);
    return element;
  }

  private _setMetaElementAttributes(tag: MetaDefinition, el: HTMLMetaElement): HTMLMetaElement {
    Object.keys(tag).forEach((prop: string) => el.setAttribute(this._getMetaKeyMap(prop), tag[prop]));
    return el;
  }

  private _parseSelector(tag: MetaDefinition): string {
    const attr: string = tag.name ? 'name' : 'property';
    return `${attr}="${tag[attr]}"`;
  }

  private _containsAttributes(tag: MetaDefinition, elem: HTMLMetaElement): boolean {
    return Object.keys(tag).every((key: string) => elem.getAttribute(this._getMetaKeyMap(key)) === tag[key]);
  }

  private _getMetaKeyMap(prop: string): string {
    return META_KEYS_MAP[prop] || prop;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const META_KEYS_MAP: { [prop: string]: string } = {
  httpEquiv: 'http-equiv'
};
